<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";

import SMSSender from "./parts/sms-sender";
import Swal from "sweetalert2";
import RequestTrackingServices from "../../services/RequestTrackingServices";
import { VueEditor } from "vue3-editor";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default {
  page: {
    title: "Détails de la souscription",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      fields: {
        description: "",
        images: [],
      },
      title: "Suivi de colis",
      items: [
        {
          text: "Liste des colis",
          href: "/request-trackings",
        },
        {
          text: "Détails sur le colis",
          active: true,
        },
      ],
      currentSubscription: { steps: [] },
      isConfirmStepModalOpen: false,
      currentStep: null,
    };
  },
  components: {
    Layout,
    PageHeader,
    SMSSender,
    VueEditor,
  },
  computed: {},
  watch: {},
  created() {},
  beforeMount() {
    this.getCurrentSubscription();
  },

  methods: {
    getCurrentSubscription() {
      RequestTrackingServices.getById(this.$route.params.id)
        .then((data) => {
          this.currentSubscription = data.tracking;
        })
        .catch((er) => {
          console.log(er);
        });
    },
    openConfirmStepModal($step) {
      this.currentStep = $step;
      this.isConfirmStepModalOpen = true;
    },
    async handleConfirmStep() {
      Swal.fire({
        title: "Êtes-vous sûr de confirmer la validation de l'étape en cours ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, confirmer !",
      }).then((result) => {
        if (result.value) {
          this.$startLoading();

          const formData = new FormData();
          formData.append("description", this.fields.description);
          for (const item of this.fields.images) {
            formData.append("images", item.file);
          }

          // console.log(formData);
          // return;

          RequestTrackingServices.confirmStep(
            this.currentSubscription.id,
            this.currentStep.id,
            formData
          )
            .then(() => {
              this.$saSuccess("Étape validée avec succès !");
              this.isConfirmStepModalOpen = false;
            })
            .catch(() => {
              this.$saError("Erreur lors de la mise à jour de l'étape !");
            })
            .finally(() => {
              this.getCurrentSubscription();
            });
        }
      });
    },
    handleRemove(image) {
      this.fields.images.splice(
        this.fields.images.findIndex((item) => item.base64 === image.base64),
        1
      );
    },
    async uploadImages(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const base64 = await convertBase64(file);
        this.fields.images = [...this.fields.images, { base64, file }];
      }
    },
    async handleDrop(event) {
      for (const item of [...event.dataTransfer.items]) {
        const file = item.getAsFile();
        const base64 = await convertBase64(file);
        this.fields.images = [...this.fields.images, { base64, file }];
      }
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
  },
  mounted() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal
      v-model="isConfirmStepModalOpen"
      hide-footer
      title="Confirmation de l'étape"
      class="v-modal-custom"
      header-class="bg-light p-3"
    >
      <form action="#" id="addform">
        <div class="mb-3">
          <label for="paid_at" class="form-label">
            Entrez une description
          </label>
          <VueEditor v-model="fields.description" />
        </div>

        <div class="card">
          <div class="card-body">
            <div class="vstack gap-2">
              <div class="row align-items-center">
                <div class="col-6">
                  <h5 class="fs-14 mb-1">Ajouter des images</h5>
                  <p class="text-muted">
                    Ajouter des images de la galerie de produits.
                  </p>
                </div>

                <div class="col-6 d-flex justify-content-end">
                  <label class="btn btn-primary" for="img-file-input">
                    + Ajouter des images
                  </label>
                  <input
                    id="img-file-input"
                    type="file"
                    class="img-file-input d-none"
                    multiple
                    @change="uploadImages"
                    accept=".png, .jpg, .bmp"
                  />
                </div>

                <label
                  class="drop-zone"
                  for="img-file-input"
                  @drop.prevent="handleDrop"
                  @dragenter.prevent
                  @dragover.prevent
                >
                  <div>
                    Cliquez pour ajouter ou des images ou glissez-déposez
                  </div>
                </label>
              </div>
              <!-- <DropZone @drop.prevent="drop" @change="selectedFile" /> -->

              <div class="row">
                <div
                  class="col-4 col-md-4"
                  style="position: relative"
                  v-for="image in fields.images"
                  :key="image"
                >
                  <img
                    style="max-width: 95%"
                    class="w-100"
                    :src="image.base64"
                    alt=""
                  />
                  <div
                    style="
                      position: absolute;
                      right: 5px;
                      top: -10px;
                      background: gray;
                      height: 20px;
                      width: 20px;
                      text-align: center;
                      vertical-align: middle;
                      border-radius: 50%;
                      color: white;
                      cursor: pointer;
                    "
                    @click="handleRemove(image)"
                  >
                    X
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer v-modal-footer">
          <div class="hstack gap-2 justify-content-end">
            <button
              type="button"
              id="closemodal"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="handleConfirmStep"
            >
              Confirmer l'étape
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <div class="row">
      <div
        class="col-xxl-4 col-sm-6 project-card"
        v-for="(item, index) of currentSubscription.steps"
        :key="index"
      >
        <div class="card">
          <div class="card-body">
            <div class="p-3 mt-n3 mx-n3 bg-soft-info rounded-top">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h5 class="mb-0 fs-14">
                    {{ item.title }}
                  </h5>
                </div>
              </div>
            </div>

            <div class="py-3">
              <div class="row gy-3">
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Niveau</p>
                    <div :class="`badge badge-soft-info fs-12`">
                      {{ index + 1 }} /
                      {{ currentSubscription.steps.length }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Statut</p>
                    <h5
                      :class="`badge badge-soft-${
                        !item.registered_date ? 'warning' : 'success'
                      } fs-12`"
                    >
                      {{ !item.registered_date ? "En cours" : "Terminé" }}
                    </h5>
                  </div>
                </div>
              </div>

              <div class="row gy-3">
                <div class="col-6 mb-2">
                  <div>
                    <p class="text-muted mb-1">Validation automatique</p>
                    <div :class="`badge fs-12`">
                      {{ item.auto_validate ? "OUI" : "NON" }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Le</p>
                    <h5>
                      {{ item.due_date ? new Date(item.due_date).toLocaleString() : "-" }}
                    </h5>
                  </div>
                </div>
              </div>

              <div class="row gy-3 mb-2">
                <div
                  v-if="index + 1 == currentSubscription.current_step"
                  class="d-flex justify-content-between gx-3"
                >
                  <button
                    @click.prevent="openConfirmStepModal(item)"
                    class="me-1 w-100 btn btn-success"
                  >
                    Confirmer cette étape
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="card card-animate">
      <div class="card-header border-0">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 flex-grow-1">Envoyer un SMS manuel</h5>
        </div>
      </div>
      <div class="card-body">
        <SMSSender
          :currentSubscription="currentSubscription"
          @reloadDatas="getCurrentSubscription"
        />
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.drop-zone {
  width: 96%;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.2);
}
.drop-zone:hover {
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
}
</style>
